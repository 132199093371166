.MeetingPlaceDropDownList {
    position: relative;
    font-family: 'Lively Headline';
    justify-content: space-between;
    align-items: center;

    width: 287px;
    height: 68px;

    /* Background/Primary Alternative Light */

    background: #F7F4EF;
    border-radius: 20px;

    .selectionArea {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 8px 16px;
        height: 100%;

        // Disabled till clicking on the meetingplace selector is disabled
        // cursor: pointer;
        .heading {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: #333333;
        }
        .text {
            font-family: 'Noto IKEA Latin';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #767571;
        }
    }
    ul {
        margin-top: -15px;
        padding: 0;
        background: #F7F4EF;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        // height: 0;
        height: max-content;
        overflow: hidden;
        transition: 0.4s;

        li{
            padding: 10px 16px;
            list-style-type: none;
            font-weight: 400;
            color: #666666;
            &:hover {
                // Disabled till clicking on the meetingplace selector is disabled
                // cursor: pointer;
                font-weight: 700;
                color: #333333;
            }
        }        
    }

    .caret {
        /* Chevron Down */
        position: absolute;
        right: 8%;
        top: 42%;
    }
    // &:hover ul {
    //     height: max-content;
    // } 
}