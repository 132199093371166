// .Footer {}

// @value brandFooter from '../../styles/colors.css';
@import '../../assets/stylesheets/variables/Colors.scss';

.container {
  width: 100%;
  justify-content: stretch;
  z-index: 1;
  height: 300px;
  display: flex;


}

.BackgroundView {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.FooterMainView {
  position: absolute;
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 26px 152px;
}

.firstSection {
  background-image: url("../../assets/svg/footerStrokeone.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  height: 100%;
}

.secondSession {
  background-image: url("../../assets/svg/footerStroketwo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  height: 100%;
}

.detailsContainer {
  height: 100%;
  width: 100%;
}

.logo {
  margin: 26px 0px 0px 152px;
  height: 68px;
  // width: 68px;
  left: 0px;
  top: 0px;
  border-radius: 0px;
}

.detailsinfoContainer {
  margin: 0px 0px 0px 152px;
  width: 100%;
  padding-top: 96px;
  display: flex;
}

.detailsinfoContainer2 {
  margin: 0px 0px 0px 0px;
  width: 100%;
  padding-top: 192px;
  display: flex;

}

.aboutUS {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.privacy {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottominfo {
  margin: 0px 0px 0px 152px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
}

.text {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  color: #333333;
  font-family: Noto IKEA Latin;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #333333;
    font-weight: bold;
    text-decoration: none;
  }
}


.mplogo-footer {
  height: 68px;
  // width: 68px;
  border-radius: 0px;
  width: auto;
  @media screen and (max-width: 900px) {
    height: 51px;
  }
}

.LinkContainer {
  width: 100%;
  flex-direction: row;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 768px) {

  .FooterMainView {
    padding: 16px 16px;
  }

  .LinkContainer {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 16px;
  }

  .secondSession {
    display: none;
  }

  .firstSection {
    width: 100%;
  }
}

.link {
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
}

.footerBg {
  background-image: url("../../assets/images/footer-stroke.png");
  // background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footerBgMobile {
  // background-image: none;
  // overflow: hidden;
  background-image: url("../../assets/images/d-stroke-06.png");
  // background-position-x: -90px;
  // background-position-y: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.link2 {
  margin: 0;
  font-size: 12px;
  font-family: Noto IKEA Latin;
  font-weight: 400;
  line-height: 1.5;
  color: inherit;
  // text-decoration: none !important;

  // &:hover {
  //   color: #0a58ca;
  //   text-decoration: underline !important;
  // }
}