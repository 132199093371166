.PageContentHolder {

    // since navbar is not fixed anymore
    // margin-top: 144px;

    
    // min-height: calc(100vh - 384px);
    min-height: calc(100vh - 144px);
    // @media screen and (max-width: 900px) {
    //     margin-top: 80px;
    // }
}

.ContainerStyle {
    background-color: #FFF;
    min-height: calc(100vh - 200px);
    width:100%;
    padding-right:15px;
    padding-left:15px; 
    margin-right:auto;
    margin-left:auto
}

@media only screen and (max-width: 768px) {
    .ContainerStyle {
        padding: 0%  12px;
    }
    .PageContentHolder {
        min-height: calc(100vh - 322px);
    }
}

@media (min-width:576px){.ContainerStyle{max-width:540px}}
@media (min-width:768px){.ContainerStyle{max-width:720px}}
@media (min-width:992px){.ContainerStyle{max-width:960px}}
@media (min-width:1200px){.ContainerStyle{max-width:1140px}}