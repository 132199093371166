.site-logo-focus {
    outline-offset: 4px;
    outline: 1px solid #767571;
}

.site-logo-container {
    &:focus-visible {
        outline: none;
    }
}

.activeLogo {
    cursor: pointer;
}