.publicRoute {
  background-image: url("../assets/svg/pagebackground.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.privateRoute {
  position: absolute;
  width: 100%;
  min-height: 100vh;
}

.masterPopup {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
}

.masterEditPopup {
  z-index: 101;
}

.editPopupContent {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  min-width: 500px;
}

.editPopupRow {}

.masterEditBackground {
  position: absolute;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: -30px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.alertTitle {
  display: block;
  font-weight: bold;
  margin-top: 5px;
}

.alertMessage {
  display: block;
  font-weight: normal;
  margin-top: 10px;
}

.alertOkayBtn {
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.container {
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: brandWhite;
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 20px 64px 0px 64px;
  z-index: 1;
}

.profileDetails {
  display: flex;
  align-items: center;
  height: 100%;
}

.languageButton {
  height: 50px;
  width: 50px;
}

.dropdownStyle {

  width: 50px;
  height: 50px;
  background-color: #EEE9DE;
  border-radius: 50px;
}

.overridestyleItem {

  height: 40px;
  background-color: #F7F4EF;
  text-align: center;
  border-radius: 20px;
  /*border-bottom: 1px solid #D7CFC6;*/


}

.overridestyleItem:hover {

  font-family: Lively Headline;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  background: #F7F4EF;


}

.overridestyleMenu {
  width: 150px;
  height: 120px;
  margin-top: 25px;
  background-color: #F7F4EF;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
}

.overridestyleButton {
  background-color: transparent !important;
}

.overridestyleTitle {
  font-family: Lively Headline;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #767571;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;


}

@media only screen and (max-width: 768px) {
  .editPopupContent {
    min-width: 80%;
  }

  .masterEditPopup {
    padding: 12px;
    flex: 1;
  }
}

#main-content {
  &:focus-visible {
    outline: none;
  }
}