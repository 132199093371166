// .DropDown {}


// @value brandWhite,defaultGray,darkerGray,primaryFont from '../../../styles/colors.css';
@import '../../assets/stylesheets/variables/Colors.scss';

.dropdownContainer{
    max-width: 185px;
    margin: 0 20px 20px 20px;
}

 .dropdownButtonlang{
    border-radius: 50px;
    min-width: 50px;
    height: 50px;
    font-family: Lively Headline;
    font-size: 14px;
    font-style: normal;
    line-height: 14px;
    font-weight: 700;    // background:#EEE9DE;
    background:#FFDB00;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: border-radius 0.5s;
    color: #474747;
} 

.loggedIn{
    background: #eae3da;
    &:hover{
        background: #D7CFC6!important;
    }
    &:focus{
        background: #D7CFC6!important;
    }
}


.dropdownButtonlang:hover {
    background:  #FDC300;
    color: #474747;
}

.dropdownButtonlang:focus {
    box-shadow: none !important;
    background:  #FDC300;
}

.dropdownTitle{
    display: flex;
    align-items: center;
    color:#474747;
}


// ******************* Language selector **********************
.langDropdownStyle {
    width: 50px;
    height: 50px;
    // background-color: #EEE9DE;
    border-radius: 50px;
    margin: 47px 48px;
    @media only screen and (max-width: 900px) { 
        margin: 16px 0px
    }
  }
  
  .overridestyleButton {
    background-color: transparent !important
  }
  .overridestyleTitle {
    font-family: Lively Headline;
    font-size: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: #474747;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .overrideStyleItem {
    max-height: 40px;
    background-color: #F7F4EF;
    color: #212529 !important;
    text-align: center;
    border-radius: 20px;
    font-weight: 700 !important;
    font-size: 1rem !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:hover{
      font-style: normal;
      background: #F7F4EF !important;
      margin-left: -10px;
      flex-direction: row;
      justify-content: center;
      &::before {
        display: inline-block;
        content: '';
        border-radius: 50%;
        height: 4px;
        width: 4px;
        margin-right: 6px;
        background-color: #474747;
      }
    }
  }

  .overrideStyleMenu {
    margin-top: 5px;
    margin-left: -60px;
    border-radius: 20px;
    @media only screen and (max-width: 900px) { 
        margin-left: -10px; 
    }
  }