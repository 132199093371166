.spinner-container {
  background-color: rgba(0,0,0, 0.25);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // width: 100%;
  // height: 100%;

  .spinner-box {
    align-items: center;
    width: 451px;
    height: 269px;
    background-color: #fff;
    border-radius: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .sp-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      .spinner1 {
        width: 56px;
        height: 56px;
        margin: auto;
        margin-bottom: 20px;
        
        .spiner-image {
          width: 100%;
          height: 100%;
        }
      }
  
      .text-box {
        text-align: center;
        width: 100%;
        .processing {
          margin: 0;
          font-family: 'Noto IKEA Latin';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: #474747;
        }
        .wait {
          margin: 0;
          font-family: 'Noto IKEA Latin';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #7e7e7e;
        }
      }
    }
    
    .spinner {
        /* Spinner size and color */
        width: 96px;
        height: 96px;
        border-top-color: #444;
        border-left-color: #444;
      
        /* Additional spinner styles */
        // animation: spinner 800ms linear infinite;
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-style: solid;
        border-width: 6px;
        border-radius: 50%;  
        box-sizing: border-box;
        display: inline-block;
        vertical-align: middle;
      }
      
      /* Optional — create your own variations! */
      .spinner-large {
        width: 5rem;
        height: 5rem;
        border-width: 6px;
      }
      
      .spinner-slow {
        animation: spinner 1s linear infinite;
      }
      
      .spinner-color {
        border-top-color: #FFDB01;
        border-left-color: #FFDB01;
      }
  }  
}

.spin {
  animation: spinner 800ms linear infinite;
}

/* Animation styles */
@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}