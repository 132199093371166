.TopNavigation {
    // position: fixed !important;
    box-shadow: none !important;
}

.mplogo {
    height: 68px;
    min-height: 40px;
    max-height: 80px;
    width: auto;

    @media screen and (max-width: 900px) {
        height: 32px;
        margin-top: 16px;
        margin-left: 0;
    }

    @media screen and (max-width:1440px) and (min-width:900px) {
        margin-left: 36px;
    }
}


.mpName {
    font-size: 10px;
    line-height: 18px;
    color: #767571
}

.tnProfile {}

// @media screen and (max-width: 900px) {
//     .mplogo {
//         height: 32px;
//         margin-top: 16px;
//         margin-left: 0;
//     }
// }