.ProgressBarIcon {}

.ico_icon {
    width: 24px;
    height: 24px;
    fill: currentColor;
    fill-rule: evenodd;
    stroke: none;
    vertical-align: middle;
  }
  .XSmall {
    width: 13px;
    height: 13px;
  }
  .Small {
    width: 20px;
    height: 20px;
  }
  .Medium {
    width: 24px;
    height: 24px;
  }
  .Normal {
    width: 30px;
    height: 30px;
  }
  .Large {
    width: 48px;
    height: 48px;
  }
  .XLarge {
    width: 72px;
    height: 72px;
  }
  .XXLarge {
    width: 896px;
    height: 802px;
  }
  .Rotate {
    transform-origin: 50% 50%;
    animation: rotate 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0);
      opacity: 1;
    }
    50% {
      transform: rotate(180deg);
      opacity: 0.9;
    }
    100% {
      transform: rotate(360deg);
      opacity: 1;
    }
  }
  