.AccessDenied3 {
    height: 100vh;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

// .errorCode{
//     margin-top:10px;
//     text-align: center;
//     font-size: 5rem;
//     font-weight: 900;
//     margin-bottom: 20px;

// .errorImage{
//     max-height: 350px;
//     max-width: 450px;
// }

// }

// .topHeading{
//     text-align: center;
//     font-size: 3em;
//     margin-bottom: 10px;
//     font-weight: 900;
// }
// .description{
//     padding: 0 10px 0 10px;
//     text-align: center;
//     margin-bottom: 25px;
// }
// .backButton{
//     font-size: 1.1em;
//     font-weight: 900;
//     background-color: #3fa4f6;
//     color: white;
//     height: 35px;
//     width: 90px;
//     border: 2px white solid;
//     border-radius: 25px;

// }