// .ProgressBarPopup {}

.pop_popup_background{
    position: fixed;
    top: -11px;
    left: 0;
    bottom: 0;
    z-index: 999;
    width: 100%;
    float: left;
    background-color: #000000;
    opacity: 0.5;
  }
  
  .pop_popup{
    width: 100%;
    height: 100%;
    position: fixed;
  }
  .pop_popup_body{
    display: inline-block;
    cursor: pointer;
    z-index: 1000;
    margin: auto;
    text-align: left;
    padding: 10px;
    border-radius: 3px;
    background-color: #ffffff;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .pop_popup_title{
    display: inline-block;
    width: 100%
  }
  
  .pop_popup_close_icon{
    width: 30px;
    float: right;
  }