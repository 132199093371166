@import '../../assets/stylesheets/variables/Colors.scss';


.LanguageSelector {
  
}
.profileDetails {
  display: flex;
  align-items: center;
  height: 100%;
}
.profile {
  height: 50px;
  width: 50px;
}
.profileName {
  padding: 0px 45px
}
.languageButton {
  height: 50px;
  width: 50px;
}
.dropdownStyle {
  width: 50px;
  height: 50px;
  background-color: #EEE9DE;
  border-radius: 50px;
  margin: 47px 48px;
   @media only screen and (max-width: 900px) { 
      margin: 16px 0px
  }
}
.overridestyleItem {
  height: 40px;
  background-color: #F7F4EF;
  text-align: center;
  border-radius: 20px;
  font-weight: bold;
  &:active {
    color: #474747
  }
  &::before {
    display: inline-block;
    content: '';
    border-radius: 50%;
    height: 4px;
    width: 4px;
    margin-right: 6px;
    margin-bottom: 4px;
    background-color: #F7F4EF;
  }
  &:focus {
    &::before {
      display: inline-block;
      content: '';
      border-radius: 50%;
      height: 4px;
      width: 4px;
      margin-right: 6px;
      margin-bottom: 4px;
      background-color: #F7F4EF;
    }
  }
  &:hover {
    &::before {
      display: inline-block;
      content: '';
      border-radius: 50%;
      height: 4px;
      width: 4px;
      margin-right: 6px;
      margin-bottom: 4px;
      background-color: #474747;
    }
  }
}
.overridestyleItem:hover {
  font-style: normal;
  background: #F7F4EF;
  &::before {
    display: inline-block;
    content: '';
    border-radius: 50%;
    height: 4px;
    width: 4px;
    margin-right: 6px;
    margin-bottom: 4px;
    background-color: #474747;
  }
}
.overridestyleMenu {
  width: 150px;
  height: auto;
  margin-top: 25px;
  background-color: #F7F4EF;
  border-radius: 20px;
  box-sizing: 0px 4px 4px rgba(0, 0, 0, 0.12);
}
.overridestyleButton {
  background-color: transparent !important
}
.overridestyleTitle {
  font-family: Lively Headline;
  font-size: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #474747;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}


// .profileDetails {
//   display: flex;
//   align-items: center;
//   height: 100%;
// }

// .profile {
//   height: 50px;
//   width: 50px;
// }

// .profileName {
//   padding: 0px 45px;
// }

// .languageButton {
//   height: 50px;
//   width: 50px;
// }

// .langDropdownStyle {
//   width: 50px;
//   height: 50px;
//   background-color: #EEE9DE;
//   border-radius: 50px;
//   margin: 47px 48px;
// }

// .langOverridestyleItem {
//   height: 40px;
//   background-color: #F7F4EF;
//   text-align: center;
//   border-radius: 20px;
//   font-weight: bold;
//   &:active{
//     color: #474747;
//   }
//   &::before {
//     display: inline-block;
//     content: '';
//     border-radius: 50%;
//     height: 4px;
//     width: 4px;
//     margin-right: 6px;
//     margin-bottom: 4px;
//     background-color: #F7F4EF;
//   }
//   &:focus {
//     &::before {
//       display: inline-block;
//       content: '';
//       border-radius: 50%;
//       height: 4px;
//       width: 4px;
//       margin-right: 6px;
//       margin-bottom: 4px;
//       background-color: #F7F4EF;
//     }
//   }
//   &:hover{
//     &::before {
//       display: inline-block;
//       content: '';
//       border-radius: 50%;
//       height: 4px;
//       width: 4px;
//       margin-right: 6px;
//       margin-bottom: 4px;
//       background-color: #474747;
//     }
//   }
// }

// .langOverridestyleItem:hover {
//   // font-family: Lively Headline;
//   font-style: normal;
//   // font-weight: bold;
//   // font-size: 14px;
//   // line-height: 18px;
//   background: #F7F4EF;
//   &::before {
//     display: inline-block;
//     content: '';
//     border-radius: 50%;
//     height: 4px;
//     width: 4px;
//     margin-right: 6px;
//     margin-bottom: 4px;
//     background-color: #474747;
//   }
// }

// .langOverridestyleMenu {
//   width: 150px;
//   height: auto;
//   margin-top: 25px;
//   background-color: #F7F4EF;
//   border-radius: 20px;
//   box-shadow: 0px 4px 4px rgba(0; 0, 0, 0.12);
// }

// .langOverridestyleButton {
//   background-color: transparent !important;
// }

// .langOverridestyleTitle {
//   font-family: Lively Headline;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 14px;
//   line-height: 14px;
//   color: #474747;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// @media only screen and (max-width: 900px) { 
//   .langDropdownStyle {
//     margin: 16px 0px;
//   }
// }